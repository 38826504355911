import type { NextPage } from 'next';
import { Box, Tabs, Tab, Typography, Skeleton } from '@mui/material';
import { useGlobalState } from '../../context/globalState';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';

interface Props {
  currentTab: string;
  handleTabChange: (value: string) => void;
  loading: boolean;
  companyTotalCount: number;
  peopleTotalCount: number;
  showRelation: boolean;
  modifyStyles: boolean;
  metrics?: boolean;
  loadingCompanyNumber?: boolean;
  loadingPeopleNumber?: boolean;
}

const TabsWrapper: NextPage<Props> = ({
  currentTab, handleTabChange, loading, companyTotalCount, peopleTotalCount, showRelation, modifyStyles, metrics = false,
  loadingCompanyNumber, loadingPeopleNumber
}) => {
  const { totalCompanies, totalPersons, numberFormatter } = useGlobalState();

  return (
    <Box
      className='tabs-wrapper u-relative u-pl-40'
      sx={modifyStyles ? { borderBottomColor: 'transparent !important', paddingLeft: '10px !important' } : {}}
    >
      <Box className='u-flex u-flex-space-between'>
        <Tabs
          value={currentTab}
          onChange={(event: any, value: string) => handleTabChange(value)}
          TabIndicatorProps={{
            style: {
              height: '3px',
            },
          }}
        >
          <Tab
            icon={<ApartmentRoundedIcon fontSize='medium' sx={{ color: currentTab === 'organizations' ? '#2F3B4A' : '#6B7889' }} />}
            iconPosition='top'
            value='organizations'
            label={
              <Box>
                <Typography
                  className='fs-body-medium u-text-transform-none'
                  sx={{ color: currentTab === 'organizations' ? '#2F3B4A' : '#6B7889' }}
                  color='primary'
                >
                  Organizations
                </Typography>
                {metrics ? (
                  <Typography
                    className='u-text-align-center fs-title-regular'
                    sx={{ color: currentTab === 'organizations' ? '#2F3B4A' : '#6B7889' }}
                  >
                    {loading ? (
                      <Skeleton variant='rectangular' width={90} height={24} sx={{ bgcolor: '#F5EEFA' }} />
                    ) : (
                      <span className='u-fw-500 u-flex u-flex-align-baseline u-text-transform-none u-flex-justify-center'>
                        {loadingCompanyNumber ? (
                          <Skeleton variant='rectangular' width={45} height={24} sx={{ bgcolor: '#F5EEFA', top: '4px' }} className='u-relative' />
                        ) : (
                          numberFormatter(+companyTotalCount)
                        )}
                        {showRelation ? (
                          <span className='tabs-total-wrapper fs-body-regular u-relative u-pl-8'>of {numberFormatter(+totalCompanies)}</span>
                        ) : null}
                      </span>
                    )}
                  </Typography>
                ) : null}
              </Box>
            }
            sx={{ minWidth: '150px', justifyContent: 'flex-start' }}
          />
          <Tab
            icon={<GroupsRoundedIcon fontSize='medium' sx={{ color: currentTab === 'people' ? '#2F3B4A' : '#6B7889' }} />}
            iconPosition='top'
            value='people'
            label={
              <Box>
                <Typography
                  className='fs-body-medium u-text-transform-none'
                  sx={{ color: currentTab === 'people' ? '#2F3B4A' : '#6B7889' }}
                  color='primary'
                >
                  People
                </Typography>
                {metrics ? (
                  <Typography
                    className='u-text-align-center fs-title-regular'
                    sx={{ color: currentTab === 'people' ? '#2F3B4A' : '#6B7889' }}
                  >
                    {loading ? (
                      <Skeleton variant='rectangular' width={90} height={24} sx={{ bgcolor: '#F5EEFA' }} />
                    ) : (
                      <span className='u-fw-500 u-flex u-flex-align-baseline u-text-transform-none u-flex-justify-center'>
                        {loadingPeopleNumber ? (
                          <Skeleton variant='rectangular' width={45} height={24} sx={{ bgcolor: '#F5EEFA', top: '4px' }} className='u-relative' />
                        ) : (
                          numberFormatter(+peopleTotalCount)
                        )}
                        {showRelation ? (
                          <span className='tabs-total-wrapper fs-body-regular u-relative u-pl-8'>of {numberFormatter(+totalPersons)}</span>
                        ) : null}
                      </span>
                    )}
                  </Typography>
                ) : null}
              </Box>
            }
            sx={{ minWidth: '150px', justifyContent: 'flex-start' }}
          />
        </Tabs>
      </Box>
    </Box>
  );
};

export default TabsWrapper;
