import type { NextPage } from 'next';
import { Box, CircularProgress, Typography } from '@mui/material';

interface Props {
  size: string;
}

const SearchFilterIndicator: NextPage<Props> = ({ size }) => {
  return (
    <Box className='u-flex u-flex-align-center u-flex-justify-center u-flex-direction-column u-py-32' key='search-indicator'>
      <CircularProgress color='primary' />
      <Typography className={`u-mt-12 u-fw-700 fs-title-${size}`}>Searching...</Typography>
      <Typography className={`fs-title-${size}`}>Loading data</Typography>
    </Box>
  );
};

export default SearchFilterIndicator;
