import type { NextPage } from 'next';
import Image from 'next/image';
import { Box, Stack, Typography } from '@mui/material';
import searchIcon from '../../public/images/search-icon.svg';

const EmptyFilter: NextPage = () => {
  return (
    <Box className='u-py-40 empty-filter'>
      <Stack height='100%' alignItems='center' justifyContent='center'>
        <Box
          className='u-flex u-flex-justify-center u-flex-align-center u-w-64-px u-h-64-px u-br-50'
          sx={{ background: '#D9D9D9', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}
        >
          <Image
            src={searchIcon}
            alt='Search icon'
          />
        </Box>
        <Typography className='fs-title-regular u-mt-12'>No Results</Typography>
      </Stack>
    </Box>
  );
};

export default EmptyFilter;
